import React from 'react';
import styled, { createGlobalStyle } from 'styled-components'
import Arrow from './arrow'
import Copy from "copy-to-clipboard";  
const GlobalStyle = createGlobalStyle`


@font-face {
    font-family: 'Dia';
    src: url('./fonts/Dia-Regular.woff2') format('woff2'),
        url('./fonts/Dia-Regular.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  body {
    margin: 0;
    background-color: #C6C7CC;
    color: #313545;
    font-family: Dia ,-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
  }

  a{
    text-decoration: none;
    color: inherit;
    justify-items: center;
    transition: all 0s;
    padding-right: 8px;
    &:hover{
      opacity: .5;
      transition: all .5s;
    }
  }

  svg{
    width: 11px;
    height: 11px;
    transform: translateY(1px);
  }

`

function CopyEmail () {
  Copy("enniodybeli@gmail.com")
  alert("✨ Email address copied to clipboard ✨")
};


const GridContainer = styled.div`
  position: absolute; 
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr 2fr 1fr 0.5fr;
  grid-gap: 1.5rem;
  top: 2rem;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
  @media (max-width: 56rem) {
    grid-template-columns: 100% ;
    grid-template-rows: .75fr .5fr 1fr;
  }
  
`
const TextBox = styled.div`
  ${props => props.positionDesktop && `grid-area: ${props.positionDesktop}; max-width: 75%`};
  @media (max-width: 56rem) {
    ${props => props.positionMobile && `grid-area: ${props.positionMobile}; max-width: 100%`};
  }
`

const Flex = styled.div`
  display: flex;
  a{
    margin-right: 16px;
  }
  @media (max-width: 45rem) {
    flex-direction: column;
    margin-top: 1rem
    div{
      margin-bottom: 2rem;
    }
  }
`




function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <GridContainer>
        <TextBox>Ennio Dybeli<br/>Visual & Product Design</TextBox>
        <TextBox positionDesktop="3/2" positionMobile="2/1"><span>
          Barcelona based designer with an interest in Autonomism, decentralization and visual systems. Currently working through<a href="https://oda.services" target="_blank" rel="noopener noreferrer" > ODA <Arrow /> </a>  Previously part of Apple's design team in Cupertino. Portfolio upon request.</span>
        </TextBox>
        <TextBox positionDesktop="4/2" positionMobile="3/1" > 
          <Flex>
            <a href="https://www.are.na/ennio-dybeli" target="_blank" rel="noopener noreferrer" > Are.na  <Arrow /> </a>
            <a href="https://www.instagram.com/enniodybeli/" target="_blank" rel="noopener noreferrer" > Instagram <Arrow /> </a> 
            <a href="https://www.linkedin.com/in/enniodybeli/" target="_blank" rel="noopener noreferrer" > Linkedin <Arrow /> </a> 
            <a href="https://www.figma.com/@ennio" target="_blank" rel="noopener noreferrer" > Figma <Arrow /> </a> 
            <a  onClick={CopyEmail} href="#"> Email <Arrow /> </a> 
          </Flex>
        </TextBox> 
      </GridContainer>
    </div>
  );
}

export default App;
