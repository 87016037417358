import React from 'react';

function Arrow() {
    return(
    <svg fill="#313545" xmlns="http://www.w3.org/2000/svg" alignment-baseline="baseline">
        <path d="M8.59225 9.85421V1.40774H0.145767V0H10V9.85421H8.59225Z" fill="#313545"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9.00457L8.09455 0.910042L9.08998 1.90547L0.995428 10L0 9.00457Z" fill="#313545"/>
    </svg>

    ) }

export default Arrow;